import { PAGES } from '@repairer-frontend/routes';
import { Claim, UserData } from './user-types';

/**
 * List of roles available for users logging into RepairerFrontend.
 */
export const USER_ROLES = [
    'Estimator',
    'PartsOrderer',
    'SystemAdmin',
    'SupportLead',
    'Support',
    'HeadOfficeCompanyAdmin',
    'FeedbackManager',
    'RepairerAuditor',
    'RepairerCompanyAdmin',
    'CompanyAdmin',
    'FinancialManager',
    'RatingViewer',
    'RepairerPaymentsManager',
    'RemoteEstimator',
] as const;

/**
 * Type representing a user role.
 */
export type userRoles = (typeof USER_ROLES)[number];

/**
 * Generates an array of claims applicable to Feature Flag intercept responses.
 * @param roles - Array of repairer roles.
 * @returns Array of Claim objects.
 */
export const getRoleClaims = (roles: Array<userRoles>): Array<Claim> => {
    return roles.map((role) => ({
        type: 'role',
        value: role.toString(),
    }));
};

/**
 * Checks if the user has at least one of the required roles.
 * @param userData - User data containing roles.
 * @param allowedRoles - Array of roles that are allowed.
 * @returns True if the user has one of the allowed roles, false otherwise.
 */
export const hasRequiredRole = (
    userData: UserData,
    allowedRoles: Array<userRoles> = [],
): boolean => {
    const allowedRolesSet = new Set<userRoles>(allowedRoles);
    return userData?.roles?.some((role) => allowedRolesSet.has(role)) ?? false;
};

/**
 * Determines the homepage URL based on the user's role. Redirects are from the UrlResolutionService.cs in PTUSA.
 *
 * @param {UserData} userData - The data of the user, including their roles.
 * @param {Set<userRoles>} userRoles - A set of user roles to check against.
 * @returns {string} The URL to navigate to based on the user's role.
 */
export const getHomepageUrl = (
    userData: UserData,
    userRoles: Set<userRoles>,
): string => {
    const matchedRoles = new Set(
        userData.roles.filter((role) => userRoles.has(role)),
    );
    if (
        (matchedRoles.has('SystemAdmin') ||
            matchedRoles.has('SupportLead') ||
            matchedRoles.has('Support') ||
            matchedRoles.has('HeadOfficeCompanyAdmin')) &&
        !(
            matchedRoles.has('Estimator') ||
            matchedRoles.has('PartsOrderer') ||
            matchedRoles.has('RemoteEstimator')
        )
    ) {
        return PAGES.settingsPreferredVendors;
    }
    return PAGES.activeJobs;
};
