import { UserData } from '@repairer-frontend/user-roles/utility';

export type ConfigType = {
    baseUrl: string;
    user: UserData;
    launchDarklyClientId: string;
};

export const getBaseUrl = () => {
    const hostname = window.location.hostname;
    const splitHost = hostname.split('.');

    // Get environment (first part of the hostname)
    let environmentName = splitHost[0];

    // Get domain
    const domain = splitHost.slice(1).join('.');

    // workaround for repairer.testpartstrader.us.com since it uses a vanity url
    // but should hit services in pp101
    if (hostname === 'repairer.testpartstrader.us.com') {
        environmentName = 'pp101repairer';
    }

    return `https://${environmentName}.${domain}`;
};

export const getApiBaseUrl = () => {
    const hostname = window.location.hostname;
    const splitHost = hostname.split('.');

    // Get environment (first part of the hostname)
    let environmentName = splitHost[0];

    // Get domain
    const domain = splitHost.slice(1).join('.');

    // workaround for repairer.testpartstrader.us.com since it uses a vanity url
    // but should hit services in pp101
    if (hostname === 'repairer.testpartstrader.us.com') {
        environmentName = 'pp101repairer';
    }

    // for localhost we can't derive the environment name from the hostname
    if (hostname === 'localhost') {
        return 'http://devrepairerapi.partstrader.io';
    }

    return `https://${environmentName}api${domain ? `.${domain}` : ''}`;
};

// Generate the configuration required for the Root Application based the URL the application is loaded.
// Here you can also override import map value for your local or test purposes
export const generateConfig = async (
    userData: UserData,
    developmentLDClientId = '6581e00e03fba30fff4b4d15', // pp101 as default dev LD client id
): Promise<ConfigType> => {
    // Runtime config
    const runningConfig = {
        launchDarklyClientId: '',
    };

    if (window.location.hostname === 'devrepairer.partstrader.io') {
        runningConfig.launchDarklyClientId = developmentLDClientId;
    } else {
        try {
            const response = await fetch(
                `https://${window.location.hostname}/config`,
            );
            const responseJson = await response.json();
            runningConfig.launchDarklyClientId =
                responseJson.launchDarklyClientId;
        } catch (error) {
            console.error('Error fetching runtime config:', error);
        }
    }

    return {
        baseUrl: '/',
        user: userData,
        launchDarklyClientId: runningConfig.launchDarklyClientId,
    };
};
