import { LaunchDarklyClientSide } from '@repairer-frontend/client-feature-flags';
import {
    ROOT_CONFIG_LOCAL_STORAGE_KEY,
    generateConfig,
    getApiBaseUrl,
} from '@repairer-frontend/root-config';
import { navigateToUrl, registerApplication, start } from 'single-spa';
import {
    constructApplications,
    constructLayoutEngine,
    constructRoutes,
} from 'single-spa-layout';
import { header, sidebar } from './loaders';

import { buildPtusFrontendUrl } from '@repairer-frontend/ptus-frontend-url-builder';
import { ShouldDisableAnimations } from '@repairer-frontend/shared/client/client-app-test-support';
import { LayoutProvider } from './LayoutProvider';
import { login } from './auth/auth';
import { job } from './loaders/job';
import microfrontendLayout from './microfrontend-layout.html?raw';
import { configureNewrelic } from './newrelic/configuration';
import { isProduction } from '@repairer-frontend/client-app-environment';
import {
    getHomepageUrl,
    USER_ROLES,
} from '@repairer-frontend/user-roles/utility';

const isCypressRunning = window['Cypress'];

if (!isProduction()) {
    if (!isCypressRunning) {
        // add the import map override dev tool
        const importMapOverrideDevTools = document.createElement(
            'import-map-overrides-full',
        );
        importMapOverrideDevTools['dev-libs'] = 'dev-libs';
        document.body.append(importMapOverrideDevTools);
    }

    // add field to the window object required for Cypress code coverage
    window['__coverage__'] = window['__coverage__'] || {};
}

configureNewrelic({
    accountId: '2839186',
    trustKey: '739791',
    agentId: '601448608',
    licenseKey: 'NRBR-4ed05250c3c6b0af49e',
    applicationId: '601448608',
});

/**
 * Dev only function to inject the vite refresh snippet into the page.
 * @param baseUrl
 */
const injectViteRefreshSnippet = (baseUrl: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'module';
    scriptElement.textContent = `
            import { injectIntoGlobalHook } from "${baseUrl}/@react-refresh";

            injectIntoGlobalHook(window);
            window.$RefreshReg$ = () => {};
            window.$RefreshSig$ = () => (type) => type;
        `;
    document.head.append(scriptElement);
};

const disableAnimationsIfRunningTests = () => {
    if (ShouldDisableAnimations()) {
        document.body.classList.add('no-animations');
    }
};

const initialize = () => {
    const data = {
        loaders: {
            header,
            sidebar,
            job,
        },
        props: {
            config: { baseUrl: '/' },
            handleError: (error: Error) => {
                console.error('Error in root', error);
            },
        },
    };

    new LayoutProvider();

    const importMap = JSON.parse(
        document.querySelector('script[type=importmap]')?.textContent ?? '{}',
    );

    // de-register the old cache service worker
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
            void registration.unregister();
        }
    });

    // Manually add the import map script for non-production environments
    if (window.location.hostname !== 'repairer.partstrader.us.com') {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src =
            'https://cdn.jsdelivr.net/npm/import-map-overrides@4.2.0/dist/import-map-overrides.min.js';
        script.fetchPriority = 'low';
        script.async = true;
        script.defer = true;

        document.head.append(script);
    }

    const routes = constructRoutes(microfrontendLayout, data);
    const applications = constructApplications({
        routes,
        loadApp({ name }: { name: string }) {
            const pathToApp =
                localStorage.getItem(`import-map-override:${name}`) ??
                importMap.imports[name];

            // enable HMR for any apps that are running locally
            if (
                pathToApp.includes('http://localhost') &&
                process.env.NODE_ENV !== 'localdev'
            ) {
                injectViteRefreshSnippet(new URL(pathToApp).origin);
            }

            return import(/* @vite-ignore */ pathToApp);
        },
    });
    const layoutEngine = constructLayoutEngine({
        routes,
        applications,
    });

    for (const app of applications) {
        registerApplication(app);
    }

    layoutEngine.activate();
    start();
    disableAnimationsIfRunningTests();
};

initialize();

/**
 * Initializes the root config settings with the provided user data.
 * @param userData The user data used for initialization.
 */
// TODO TECH-4194 In the future using top-level await might be cause a backwards compatibility break in Node
// eslint-disable-next-line unicorn/prefer-top-level-await
login().then(async (userData) => {
    if (!userData) {
        window.location.href = `${getApiBaseUrl()}/fss/auth/login?returnUrl=${window.location.href}`;
        return;
    }
    const repairerRoles = new Set(USER_ROLES);
    if (userData?.roles.some((role) => repairerRoles.has(role))) {
        const config = await generateConfig(
            userData,
            import.meta?.env?.VITE_LaunchDarkly_repairer_vnext_Client_Sdk_Key,
        );
        const { user = null } = config;

        window.localStorage.setItem(
            ROOT_CONFIG_LOCAL_STORAGE_KEY,
            JSON.stringify(config),
        );
        window.dispatchEvent(
            new StorageEvent('local-storage', {
                key: ROOT_CONFIG_LOCAL_STORAGE_KEY,
            }),
        );

        await LaunchDarklyClientSide({
            envKey: config.launchDarklyClientId,
            context: {
                kind: 'user',
                key: user ? `c-${user.company_displayid}` : '',
            },
        });

        if (window.location.pathname === '/') {
            const homeUrl = getHomepageUrl(userData, repairerRoles);
            navigateToUrl(homeUrl);
        }

        // We block user interaction on the page using the inert attribute on
        // the body until the app is fully loaded (especially feature flags) to
        // avoid weird behaviour. We do this so we can load the apps, user data
        // and feature flags all asynchronously with no blocking
        document.body.attributes.removeNamedItem('inert');
    } else {
        window.location.href = buildPtusFrontendUrl('');
    }
});
